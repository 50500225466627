import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AbpHttpInterceptor} from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import {PaymentInfrastructureMapServiceProxy, PaymentInfrastructureServiceProxy} from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.PaymentInfrastructureServiceProxy,
        ApiServiceProxies.PaymentInfrastructureMapServiceProxy,
        ApiServiceProxies.CreditCardServiceProxy,
        ApiServiceProxies.CreditCardRateServiceProxy,
        ApiServiceProxies.SystemSettingServiceProxy,
        ApiServiceProxies.ContractServiceProxy,
        ApiServiceProxies.PackageServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        {provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true}
    ]
})
export class ServiceProxyModule {
}
